import React from 'react';

const Intro = () => (
  <>
    <h3>
      This is the official record of the ManKind Project USA Organizational
      Structure.
    </h3>
    <p>
      Click on any Circle or Role to learn more about it, including its purpose
      and accountabilities.
    </p>
    <p>
      The full list of Members for any Circles is in the “Circle Members” Role
      within the circle.
    </p>
    <p>
      Roles listed as “Rep Links” serve to bring concerns of the circle to the
      larger circle, usually the Lead Organizational Circle.
    </p>
    <p>
      Circles are similar to business groups, teams, or committees. Each Circle
      has a Facilitator, a Secretary, and a Lead. Roles are roughly equivalent
      to job descriptions. Individuals within the MKP USA structure often hold
      multiple Roles. Roles can be held by volunteers, contractors, or
      employees. To learn more about the responsibilities of Role Holders, see
      the constitution.
    </p>
    <p>
      To learn more about the governance structure for the Board of MKP USA,{' '}
      <a href="https://mkpusa.org/org-charts/">click here</a>.
    </p>
    <p>
      For questions about our organizational system, email{' '}
      <a href="mailto:holacracy@mkpusa.org">holacracy@mkpusa.org</a>
    </p>
  </>
);

export default Intro;
