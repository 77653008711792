import { useContext } from 'react';
import styled from 'styled-components';

import './App.css';

import AppContext from './AppContext';
import MKPHyperLink from './components/MKPHyperLink';
import SearchInput from './components/SearchInput';
import CirclePack from './components/CirclePack';
import Intro from './components/Intro';

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function App() {
  const { selectedRole, setSelectedRole } = useContext(AppContext);

  const onRoleChange = role => {
    console.log(role);
    setSelectedRole(role);
  };

  return (
    <div className="circle-area">
      <div className="circle-pack-area">
        <TopSection>
          <MKPHyperLink />
          <SearchInput />
        </TopSection>
        <CirclePack onRoleChange={onRoleChange} />
      </div>
      <div className="circle-content-area">
        <h3>{selectedRole && selectedRole.role_name}</h3>
        <pre>
          {Object.keys(selectedRole).length > 0 ? selectedRole.text : <Intro />}
        </pre>
      </div>
    </div>
  );
}

export default App;
