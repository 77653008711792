import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';
import App from './App';
import AppContext from './AppContext';
import SearchResults from './components/SearchResults';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/search-role',
    element: <SearchResults />,
  },
]);

const RootState = () => {
  const [query, setQuery] = useState('');
  const [data, setData] = useState();
  const [selectedRole, setSelectedRole] = useState({});
  const context = {
    query,
    setQuery,
    data,
    setData,
    selectedRole,
    setSelectedRole,
  };

  return (
    <AppContext.Provider value={context}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <RootState />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
