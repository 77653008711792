import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIcon } from '../static/search.svg';
import AppContext from '../AppContext';

const Wrapper = styled.div`
  display: flex;
`;

const ResizedIcon = styled(SearchIcon)`
  height: 35px;
  width: 35px;
`;

const Input = styled.input`
  width: 400px;
  border: 5px solid #ececec;
  padding: 0 8px;
  font-size: 16px;

  &:focus {
    border-color: #808080;
  }

  &:focus-visible {
    outline: none;
  }
`;

const SearchBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 0 8px;
  background: transparent;
  border: 5px solid #ececec;
  border-left: none;

  &:hover {
    cursor: pointer;
    border-color: #808080;
    border-left: 5px solid #808080;
    margin-left: -5px;
  }
`;

const SearchInput = () => {
  const navigate = useNavigate();
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      navigate('/search-role');
    }
  };

  const { query, setQuery } = useContext(AppContext);

  return (
    <Wrapper>
      <Input
        type="search"
        placeholder="Search..."
        onKeyDown={onKeyDown}
        value={query}
        onChange={e => setQuery(e.target.value)}
      ></Input>
      <SearchBtn onClick={() => navigate('/search-role')}>
        <ResizedIcon />
      </SearchBtn>
    </Wrapper>
  );
};

export default SearchInput;
