import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AppContext from '../AppContext';

const PageContainer = styled.div`
  height: 100vh;
  overflow: scroll !important;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  height: 60px;
`;

const BackLink = styled(Link)`
  font-weight: 700;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  width: 90%;
`;

const InstructText = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const Input = styled.input`
  font-size: 14px;
  margin-left: 5px;
  flex: 1;
`;

const SearchBtn = styled.button`
  margin-left: 5px;
  font-size: 14px;
`;

const ResultContainer = styled.div`
  padding: 15px;
`;

const ResultHeader = styled.h2`
  color: #bebebe;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const RoleName = styled(Link)`
  font-size: 18px;
  font-weight: 600;
  padding-left: 15px;
`;

const RoleText = styled.p`
  padding: 15px 30px;
`;

const getHightlightedText = (data, query) => {
  const regex = new RegExp(query.split(' ').join('|'), 'gi');
  const shortenRegex = new RegExp(
    `(.{0,200})(${query.split(' ').join('|')})(.{0,200})`,
    'gi'
  );

  // search
  const results = data.roles.filter(role => {
    if (role.role_name.match(regex) || role.text.match(regex)) {
      return true;
    }
    return false;
  });

  // hightlight matched text
  const hightlightedResults = results.map(role => {
    const hightlightedText = role.text.replace(
      regex,
      matchedText => '<strong>' + matchedText + '</strong>'
    );
    return { ...role, text: hightlightedText };
  });

  // get portion of text
  const portionResults = hightlightedResults.map(role => {
    const portionText =
      '...' + role.text.match(shortenRegex).join('...') + '...';
    return { ...role, text: portionText };
  });

  return portionResults;
};

const SearchResults = () => {
  const { query, setQuery, data, setData } = useContext(AppContext);
  const [filteredData, setFilteredData] = useState([]);

  const filterData = useCallback(async () => {
    if (data) {
      if (query === '') {
        setFilteredData([]);
      } else {
        const results = getHightlightedText(data, query);
        setFilteredData(results);
      }
    } else {
      const fetchedData = await fetch('https://roles.mkpusa.org/data.php');
      const jsonData = await fetchedData.json();
      setData(jsonData.data);
    }
  }, [data, setData, query]);

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      filterData();
    }
  };

  useEffect(() => {
    filterData();
  }, []);

  return (
    <PageContainer id="content">
      <HeaderWrapper>
        <h1>Search</h1>
        <BackLink to="/">Back to ROLES and CIRCLES</BackLink>
      </HeaderWrapper>
      <hr />
      <InputWrapper>
        <InstructText>Enter your keywords: </InstructText>
        <Input
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
        />
        <SearchBtn onClick={() => filterData()}>Search</SearchBtn>
      </InputWrapper>
      <ResultContainer>
        <ResultHeader>Search Results</ResultHeader>
        {filteredData.length > 0 ? (
          <List>
            {filteredData.map((role, idx) => (
              <li key={idx}>
                <RoleName to={`/#${role.role_id}`}>{role.role_name}</RoleName>
                <RoleText dangerouslySetInnerHTML={{ __html: role.text }} />
              </li>
            ))}
          </List>
        ) : (
          <div>No results</div>
        )}
      </ResultContainer>
    </PageContainer>
  );
};

export default SearchResults;
